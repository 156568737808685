import {
	ChangeEventHandler,
	FormEventHandler,
	FunctionComponent,
	useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../constants/api";
import { ApiError } from "../../types";
import { PHONE_NUMBER_STORAGE_NAME } from "../../constants/storage";
import LoginPageView from "./LoginPageView";

import "./LoginPage.scss";
import Turnstile from "react-turnstile";

const LoginPage: FunctionComponent = () => {
	const [phoneNumber, setPhoneNumber] = useState("");
	const [token, setToken] = useState("");
	const [error, setError] = useState<ApiError | null>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	const handleChange: ChangeEventHandler<HTMLInputElement> = ({
		target: { value },
	}) => {
		setPhoneNumber(value);
	};

	const handleSubmit: FormEventHandler = (e) => {
		e.preventDefault();

		if (loading) {
			return;
		}

		console.log("Submitting login form...");

		const url = BASE_URL + "/login";
		const data = {
			phoneNumber,
			token,
		};

		fetch(url, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		})
			.then((res) => {
				switch (res.status) {
					case 204:
						setError(null);
						setLoading(false);
						localStorage.setItem(
							PHONE_NUMBER_STORAGE_NAME,
							phoneNumber
						);
						navigate("/verify-login-otp");
						return;
					case 400:
						return res.json();
					default:
						throw new Error(
							"API returned status code: " + res.status
						);
				}
			})
			.then((data: ApiError) => {
				setError(data);
				setLoading(false);
			})
			.catch((e) => {
				console.group("An error occured on login");
				console.log("URL", url);
				console.log("Data", data);
				console.error(e);
				console.groupEnd();

				setError({
					error: "Error",
					message:
						"An error occured while logging in. Please try again later.",
				});
				setLoading(false);
			});
	};

	const handleCloseError = () => setError(null);

	return (
		<>
			<Turnstile
				sitekey="0x4AAAAAAA3ajqSf1jUaz4Vr"
				onVerify={(token) => setToken(token)}
			/>
			<LoginPageView
				phoneNumber={phoneNumber}
				error={error}
				loading={loading}
				onChange={handleChange}
				onSubmit={handleSubmit}
				onCloseError={handleCloseError}
				onTokenChange={setToken}
			/>
		</>
	);
};

export default LoginPage;
