import { Stripe, StripeElements } from "@stripe/stripe-js";
import { createAccount, sendOtp } from "../store/createAccount/effects";
import { CreateAccountRequest } from "../types";
import useAppDispatch from "./useAppDispatch";
import useAppSelector from "./useAppSelector";
import { clearError, setError } from "../store/createAccount";

const useCreateAccount = () => {
	const dispatch = useAppDispatch();
	const { loading, sendingOtpStatus, error, status, clientSecret } =
		useAppSelector((x) => x.createAccount);

	const handleCreateAccount = (user: CreateAccountRequest) => {
		if (loading || status !== "initial") {
			return;
		}

		dispatch(createAccount({ user }));
	};

	const handleSubscribe = async (
		stripe: Stripe,
		elements: StripeElements
	) => {
		if (loading || status !== "payment") {
			return;
		}

		const { error } = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url:
					window.location.protocol +
					"//" +
					window.location.host +
					"/dashboard",
			},
		});

		if (error) {
			dispatch(
				setError({
					error: { error: error.type, message: error.message! },
				})
			);
		}
	};

	const handleRequestOtp = (phoneNumber: string, token: string) => {
		if (sendingOtpStatus === "requesting") {
			return;
		}

		dispatch(sendOtp({ phoneNumber, token }));
	};

	const handleClearError = () => {
		dispatch(clearError());
	};

	return {
		loading,
		sendingOtpStatus,
		error,
		status,
		clientSecret,
		createAccount: handleCreateAccount,
		subscribe: handleSubscribe,
		requestOtp: handleRequestOtp,
		clearError: handleClearError,
	};
};

export default useCreateAccount;
