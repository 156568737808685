import {
	CreateAccountFinaliseRequest,
	CreateAccountFinaliseResponse,
	CreateAccountRequest,
	CreateAccountResponse,
	Departures,
	RecentSearch,
	Service,
	TrackedTrain,
} from "../types";
import { AuthData } from "../utils/auth";
import { get, post, submitDelete } from "./methods";

export const getDepartures = (
	originCrs: string,
	destinationCrs: string,
	from: Date | null,
	nextToken: string | null,
	operator: string | null
) => {
	let path = `/departures/${originCrs}/${destinationCrs}?`;
	if (nextToken) {
		path += "&nextToken=" + nextToken;
	} else if (from) {
		path += "&from=" + from.toISOString();
	}

	if (operator) {
		path += "&operator=" + operator;
	}

	return get<Departures>(path);
};

export const getTrackedTrains = () => get<TrackedTrain[]>("/tracked");

export const trackTrain = (
	originCrs: string,
	destinationCrs: string,
	serviceId: string
) =>
	post("/track", {
		origin: originCrs,
		destination: destinationCrs,
		serviceId,
	});

export const stopTrackingTrain = (serviceId: string) =>
	submitDelete("/track/" + serviceId);

export const getService = (id: string) => get<Service>("/services/" + id);

export const getRecentSearches = () => get<RecentSearch[]>("/searches");

export const register = (req: CreateAccountRequest) =>
	post<CreateAccountRequest, CreateAccountResponse>("/register", req, true);

export const requestOtp = (phoneNumber: string, token: string) =>
	post("/send-otp", { phoneNumber, token }, true);

export const registerFinalise = (req: CreateAccountFinaliseRequest) =>
	post<CreateAccountFinaliseRequest, CreateAccountFinaliseResponse>(
		"/register/finalise",
		req
	);

export const refreshToken = (refreshToken: string) =>
	post<any, AuthData>("/auth/refresh", { refreshToken }, true);
