import {
	ChangeEventHandler,
	FunctionComponent,
	useEffect,
	useState,
} from "react";
import CreateAccountView from "./CreateAccountView";
import plans, { PlanType } from "../Landing/plans";
import { useNavigate, useParams } from "react-router";
import { useCreateAccount } from "../../hooks";
import { CreateAccountRequest } from "../../types";
import { Stripe, StripeElements } from "@stripe/stripe-js";
import Turnstile from "react-turnstile";

interface RouteProps {
	type: PlanType;
	[key: string]: string;
}

const initialFormData: CreateAccountRequest = {
	firstName: "",
	lastName: "",
	phoneNumber: "",
	oneTimePasscode: "",
	planType: PlanType.Trial,
	address: {
		lines: ["", ""],
		city: "",
		postcode: "",
	},
};

const CreateAccount: FunctionComponent = () => {
	const { type } = useParams<RouteProps>();
	const [selectedPlanType, setPlanType] = useState(type ?? PlanType.Trial);
	const {
		loading,
		sendingOtpStatus,
		status,
		error,
		clientSecret,
		createAccount,
		subscribe,
		requestOtp,
		clearError,
	} = useCreateAccount();
	const [formData, setFormData] = useState({
		...initialFormData,
		planType: selectedPlanType,
	});
	const [stripe, setStripe] = useState<Stripe | null>();
	const [elements, setElements] = useState<StripeElements | null>();
	const [token, setToken] = useState("");
	const navigate = useNavigate();

	const selectedPlan = plans.find((x) => x.type === selectedPlanType)!;

	const handleSubmit = async () => {
		if (loading) {
			return;
		}

		switch (status) {
			case "initial":
				createAccount(formData);
				break;
			case "payment":
				if (!stripe || !elements) {
					break;
				}

				subscribe(stripe, elements);
		}
	};

	const handleChange: ChangeEventHandler<HTMLInputElement> = (e) => {
		if (loading) {
			return;
		}

		const { name, value } = e.target;
		const newData = { ...formData };

		switch (name) {
			case "signup.firstname":
				newData.firstName = value;
				break;
			case "signup.lastname":
				newData.lastName = value;
				break;
			case "signup.phonenumber":
				newData.phoneNumber = value;
				break;
			case "signup.oneTimePasscode":
				newData.oneTimePasscode = value;
				break;
			case "signup.addressLine1":
				newData.address.lines = [value].concat(
					formData.address.lines.slice(1)
				);
				break;
			case "signup.addressLine2":
				newData.address.lines = [formData.address.lines[0]].concat(
					value
				);
				break;
			case "signup.city":
				newData.address.city = value;
				break;
			case "signup.postcode":
				newData.address.postcode = value;
				break;
		}

		setFormData(newData);
	};

	const handleChangePlan = (planType: PlanType) => {
		setPlanType(planType);
		setFormData({ ...formData, planType });
	};

	const handleStripeLoad = (
		stripe: Stripe | null,
		elements: StripeElements | null
	) => {
		setStripe(stripe);
		setElements(elements);
	};

	const handleVerifyPhoneNumber = () => {
		if (formData.phoneNumber) {
			requestOtp(formData.phoneNumber, token);
		}
	};

	useEffect(() => {
		if (status === "done") {
			navigate("/dashboard");
		}
	}, [status, navigate]);

	return (
		<>
			<Turnstile
				sitekey="0x4AAAAAAA3ajqSf1jUaz4Vr"
				onVerify={(token) => setToken(token)}
			/>
			<CreateAccountView
				plans={plans}
				plan={selectedPlan}
				status={status}
				error={error}
				formData={formData}
				canContinue={
					status === "initial" ||
					(status === "payment" && !!stripe && !!elements)
				}
				clientSecret={clientSecret}
				loading={loading}
				sendingOtpStatus={sendingOtpStatus}
				onChangePlan={handleChangePlan}
				onSubmit={handleSubmit}
				onChange={handleChange}
				onStripeLoad={handleStripeLoad}
				onVerifyPhoneNumber={handleVerifyPhoneNumber}
				onCloseError={clearError}
			/>
		</>
	);
};

export default CreateAccount;
