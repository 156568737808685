import { ChangeEventHandler, FormEventHandler, FunctionComponent } from "react";
import { ApiError } from "../../types";
import Layout from "../../components/Layout";
import { Link } from "react-router-dom";

export interface LoginPageViewProps {
	phoneNumber: string;
	error: ApiError | null;
	loading: boolean;
	onChange: ChangeEventHandler<HTMLInputElement>;
	onSubmit: FormEventHandler;
	onCloseError: () => void;
	onTokenChange: (token: string) => void;
}

const LoginPageView: FunctionComponent<LoginPageViewProps> = ({
	phoneNumber,
	error,
	loading,
	onChange,
	onSubmit,
	onCloseError,
}) => (
	<Layout>
		<div className="container py-5">
			<div className="row">
				<div className="col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-xl-4 offset-xl-4">
					{/* Title Section */}
					<h1 className="display-4 ts-4">Login</h1>
					<p>Enter your phone number to log in.</p>

					<hr />
					{/* Error Section */}
					{!!error &&
						!error.paramName &&
						error.error !== "Waitlist" && (
							<div
								className="alert alert-danger alert-dismissible show"
								role="alert"
							>
								{error.message}
								<button
									type="button"
									className="btn-close"
									aria-label="Close"
									onClick={onCloseError}
								></button>
							</div>
						)}
					{/* Form Section */}
					<form className="d-flex flex-row" onSubmit={onSubmit}>
						<input
							type="tel"
							name="phoneNumber"
							placeholder="Enter your number..."
							className="form-control"
							aria-label="phone number"
							autoComplete="tel"
							value={phoneNumber}
							onChange={onChange}
						/>

						<div className="d-grid">
							<button
								type="submit"
								className="btn btn-success"
								disabled={loading}
							>
								Login
							</button>
						</div>
					</form>
					{error?.paramName && (
						<small className="text-danger">{error.message}</small>
					)}
					<p className="pt-4">
						Don't have an account yet?{" "}
						<Link to="/signup/trial">Sign up here!</Link>
					</p>
				</div>
			</div>
		</div>
	</Layout>
);

export default LoginPageView;
